import styled from "styled-components"

export const BasicH2Title = styled.h2`
  font-size: 38px;
`

export const BasicWrapper = styled.div`
  padding: 0px 50px 0px 50px;
`

export const InBuildingImg = styled.img`
  height: 300px;
`

export const InBuildingWrapper = styled.div`
  text-align: center;
`
