import c from "../../assets/skills/programming/C.png"
import java from "../../assets/skills/programming/java.png"
import python from "../../assets/skills/programming/python.png"
import php from "../../assets/skills/programming/php.png"
import ocaml from "../../assets/skills/programming/ocaml.png"
import ada from "../../assets/skills/programming/ada.svg"
import javascript from "../../assets/skills/programming/javascript.png"
import react from "../../assets/skills/programming/react.png"
import html from "../../assets/skills/programming/html.png"
import css from "../../assets/skills/programming/css.png"
import git from "../../assets/skills/programming/git.png"

import docker from "../../assets/skills/system/docker.png"
import kubernetes from "../../assets/skills/system/kubernetes.png"
import ansible from "../../assets/skills/system/ansible.png"
import terraform from "../../assets/skills/system/terraform.png"
import antora from "../../assets/skills/system/antora.png"
import jenkins from "../../assets/skills/system/jenkins.png"
import argoWorkflow from "../../assets/skills/system/argo_workflow.png"
import luigi from "../../assets/skills/system/luigi.png"
import linux from "../../assets/skills/system/linux.png"

import switching from "../../assets/skills/networking/switch.png"
import router from "../../assets/skills/networking/router.png"
import bgp from "../../assets/skills/networking/bgp.png"
import ospf from "../../assets/skills/networking/ospf.png"
import wireshark from "../../assets/skills/networking/wireshark.png"
import wireguard from "../../assets/skills/networking/wireguard.png"
import x25 from "../../assets/skills/networking/x25.png"
import atm from "../../assets/skills/networking/atm.png"
import frameRelay from "../../assets/skills/networking/framerelay.png"
import fibreOptic from "../../assets/skills/networking/optical_fiber.png"
import tcp from "../../assets/skills/networking/tcp.png"

const skills = {
  Programmation: [
    {
      link: "https://en.wikipedia.org/wiki/C_(programming_language)",
      logo: c,
      name: "C",
    },
    {
      link: "https://fr.wikipedia.org/wiki/Java_(langage)",
      logo: java,
      name: "Java",
    },
    {
      link: "https://www.python.org/",
      logo: python,
      name: "Python",
    },
    {
      link: "https://www.php.net/",
      logo: php,
      name: "PHP",
    },
    {
      link: "https://ocaml.org/",
      logo: ocaml,
      name: "OCaml",
    },
    {
      link: "https://en.wikipedia.org/wiki/Ada_(programming_language)",
      logo: ada,
      name: "Ada",
    },
    {
      link: "https://www.javascript.com/",
      logo: javascript,
      name: "JavaScript",
    },
    {
      link: "https://react.dev/",
      logo: react,
      name: "React",
    },
    {
      link: "https://wikipedia.org/wiki/Hypertext_Markup_Language",
      logo: html,
      name: "HTML",
    },
    {
      link: "https://wikipedia.org/wiki/CSS",
      logo: css,
      name: "CSS",
    },
    {
      link: "https://git-scm.com/",
      logo: git,
      name: "Git",
    },
  ],
  Système: [
    {
      link: "https://www.docker.com/",
      logo: docker,
      name: "Docker",
    },
    {
      link: "https://kubernetes.io",
      logo: kubernetes,
      name: "Kubernetes",
    },
    {
      link: "https://www.ansible.com/",
      logo: ansible,
      name: "Ansible",
    },
    {
      link: "https://www.terraform.io/",
      logo: terraform,
      name: "Terraform",
    },
    {
      link: "https://antora.org/",
      logo: antora,
      name: "Antora",
    },
    {
      link: "https://www.jenkins.io/",
      logo: jenkins,
      name: "Jenkins",
    },
    {
      link: "https://argoproj.github.io/workflows/",
      logo: argoWorkflow,
      name: "Argo Workflow",
    },
    {
      link: "https://github.com/spotify/luigi",
      logo: luigi,
      name: "Luigi",
    },
    {
      link: "https://www.linux.org",
      logo: linux,
      name: "Linux",
    },
  ],
  Réseau: [
    {
      link: "https://www.docker.com/",
      logo: switching,
      name: "Switch",
    },
    {
      link: "https://www.docker.com/",
      logo: router,
      name: "Router",
    },
    {
      link: "https://www.docker.com/",
      logo: bgp,
      name: "BGP",
    },
    {
      link: "https://www.docker.com/",
      logo: ospf,
      name: "OSPF",
    },
    {
      link: "https://www.docker.com/",
      logo: wireshark,
      name: "Wireshark",
    },
    {
      link: "https://www.docker.com/",
      logo: wireguard,
      name: "WireGuard",
    },
    {
      link: "https://www.docker.com/",
      logo: x25,
      name: "X.25",
    },
    {
      link: "https://www.docker.com/",
      logo: atm,
      name: "ATM",
    },
    {
      link: "https://www.docker.com/",
      logo: frameRelay,
      name: "Frame Relay",
    },
    {
      link: "https://www.docker.com/",
      logo: fibreOptic,
      name: "Fibre optique",
    },
    {
      link: "https://www.docker.com/",
      logo: tcp,
      name: "TCP",
    },
  ],
}

export default skills
