import InBuilding from "../../components/building/InBuilding"
import { BasicH2Title, BasicWrapper } from "../../utils/style/CommonStyles"

function Projects() {
  return (
    <BasicWrapper>
      <BasicH2Title>Mes projets</BasicH2Title>
      <InBuilding />
    </BasicWrapper>
  )
}

export default Projects
