const colors = {
  primary: "#0F1EF5",
  secondary: "#0F67F5",
  third: "#0FF5F1",
  fourth: "#480FF5",
  fith: "#36BCF5",
  sixth: "#5F99F5",
  backgroundColor: "#eeeeee",
}

export default colors
